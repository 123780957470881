<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_analysis_detail relative w1239" v-if="dataobj">
			<div class="titlename fontsize30">{{dataobj.title}}</div>
			<div class="conbody">
				<div class="conitem fontsize18">
					<div class="itemdata">
						<div class="text1">公开号：</div>
						<div class="text2">{{dataobj.openNo}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">申请号：</div>
						<div class="text2">{{dataobj.applyNo}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">申请(专利权)人：</div>
						<div class="text2">{{dataobj.applicant}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">代理机构：</div>
						<div class="text2">{{dataobj.agency}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">代理人：</div>
						<div class="text2">{{dataobj.agent}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">公开(公告)日：</div>
						<div class="text2">{{dataobj.openDate}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">申请日：</div>
						<div class="text2">{{dataobj.applyDate}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">发明（设计）人：</div>
						<div class="text2">{{dataobj.inventor}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">当前权利人：</div>
						<div class="text2">{{dataobj.obligee}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">主分类号：</div>
						<div class="text2">{{dataobj.mainClassNo}}</div>
					</div>
					<div class="itemdata">
						<div class="text1">分类号：</div>
						<div class="text2_2">
							<div v-for="(item,index) in classNo" :key="index">{{item}}，</div>
						</div>
					</div>
					<!-- <div class="itemdata">
						<div class="text1">权利要求书：</div>
						<div class="text2">{{dataobj.rightDocument}}</div>
					</div> -->
				</div>
				<div class="right phonenone">
					<div class="imgdiv">
						<el-image
						   :src="imageList[curimg] || require('../assets/static/no_img.png')"
						    :preview-src-list="imageList">
						</el-image>
					</div>
					<div class="swiper relative" v-if="swiperOptions1.isshow">
					    <div class="swiper_con">
							<swiper ref="mySwiper1" :options="swiperOptions1">
							    <swiper-slide
							        class="swiperslide"
							        v-for="(item,index) in imageList"
							        :key="index">
									<img class="img" @click="handleImg(index)" :class="{'hover':index==curimg}" :src="item || require('../assets/static/logo.png')" />
							    </swiper-slide>
							</swiper>
						</div>
						<div class="prev pointer fontsize22" v-html="'<'" @click="slidePrev1"></div>
						<div class="next pointer fontsize22" v-html="'>'" @click="slideNext1"></div>
					</div>
				</div>
				<div class="right pcnone">
					<div class="swiper relative" v-if="swiperOptions2.isshow">
					    <div class="swiper_con">
							<swiper ref="mySwiper1" :options="swiperOptions2">
							    <swiper-slide
							        class="swiperslide"
							        v-for="(item,index) in imageList"
							        :key="index">
									<el-image
									   class="img"
									   :src="item || require('../assets/static/logo.png')"
									   :preview-src-list="imageList">
									</el-image>
							    </swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
			</div>
			<!-- 专利图片 -->
			<!-- <div class="block45"></div>
			<div class="titlediv fontsize16">
				<div class="text">专利图片</div>
			</div>
			<div class="zlImagelist" v-if="imageList.length>0">
				<el-image class="imgdiv" v-for="(url,index) in imageList" :key="index" :src="url" :preview-src-list="imageList"></el-image>
			</div>
			<div class="zlImagelist" v-if="imageList.length<=0">
				<div class="imgdiv"><img src="../assets/static/no_img.png" /></div>
			</div> -->
			<!-- 摘要 -->
			<div class="block30"></div>
			<div class="titlediv fontsize16">
				<div class="text hover">摘要</div>
			</div>
			<div class="description_text fontsize14">
				<div v-html="dataobj.description"></div>
			</div>
			<!-- 详细信息 -->
			<div class="block30"></div>
			<div class="titlediv fontsize16">
				<!-- <div class="text">详细信息</div> -->
				<div class="text" :class="{'hover':contypeIndex==0}" @click="handleconType(0)">权利要求书</div>
				<div class="text" :class="{'hover':contypeIndex==1}" @click="handleconType(1)">说明书</div>
				<div class="text" :class="{'hover':contypeIndex==2}" @click="handleconType(2)">说明书附图</div>
				<div class="text" :class="{'hover':contypeIndex==3}" @click="handleconType(3)">法律信息</div>
				<div class="text" :class="{'hover':contypeIndex==4}" @click="handleconType(4)">同族专利</div>
				<div class="text" :class="{'hover':contypeIndex==5}" @click="handleconType(5)">引证文献</div>
			</div>
			<div class="description_text fontsize14">
				<div v-html="dataobj.patentBody"></div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
//导入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			uid:0,//详情
			dataobj:null,
			imageList:[],//"https://img.gzyuexin168.com/logo.png"
			curimg:0,//当前下标
			swiperOptions1:{
				isshow:true,
				slidesPerView: 4,
			},
			swiperOptions2:{
				isshow:true,
				// loop:true,
				slidesPerView: 1,
				autoplay: {
				    delay: 3000, //自动切换的时间间隔，单位ms
				},
			},
			classNo:[],//分类号
			contypeIndex:0,//栏目下标
		};
	},
	mounted: function() {
		if(document.body.clientWidth<750){
			this.swiperOptions1.isshow = false
			// console.log(this.swiperOptions2.slidesPerView)
		}else{
			this.swiperOptions2.isshow = false
		}
		this.$store.commit('SET_CURPAGE', "analysis")
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//点击图片
		handleImg(index){
			this.curimg = index
		},
		//上一页
		slidePrev1() {
		    this.$refs.mySwiper1.swiper.slidePrev();
		},
		//下一页
		slideNext1() {
		    this.$refs.mySwiper1.swiper.slideNext()
		},
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/analysisSysDetail', query: {uid: uid }})
			this.$router.go(0);
		},
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('frontEnd/patent/InfoById', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						if(dataobj.imgUrlList){
							try{
								const imageList = JSON.parse(dataobj.imgUrlList)
								_this.imageList = _this.imageList.concat(imageList)
							}catch(e){
								_this.imageList = [""]
							}
						}
						if(dataobj.imgUrl){
							_this.imageList.push(dataobj.imgUrl)
						}
						if(dataobj.classNo){
							try{
								_this.classNo = JSON.parse(dataobj.classNo)
							}catch(e){}
						}
						_this.dataobj = dataobj
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//点击下标栏目
		handleconType(type){
			this.contypeIndex = type
		},
		
	}
};
</script>
<style lang="scss">

</style>
